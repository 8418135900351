import { Button } from "@chakra-ui/react";

function AppTextButton({ title, onClicked, backgroundColor }) {
  return (
    <Button
      paddingX={"10"}
      variant="ghost"
      onClick={onClicked}
      fontSize={18}
      fontWeight={"light"}
      backgroundColor={backgroundColor}
    >
      {title}
    </Button>
  );
}

export default AppTextButton;
