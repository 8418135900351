import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Policy from "./components/Policy";
import Term from "./components/Term";
import { ChakraProvider } from "@chakra-ui/react";
import AppScaffold from "./styles/scaffold";
import AppTheme from "./styles/theme";
import Support from "./components/Support";

function App() {
  return (
    <ChakraProvider theme={AppTheme}>
      <AppScaffold>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/term" element={<Term />} />
          <Route path="/support" element={<Support />} />
        </Routes>
      </AppScaffold>
    </ChakraProvider>
  );
}

export default App;
