import React from "react";
import { HStack, Image, Box, VStack, Divider } from "@chakra-ui/react";
import { AppText } from "../styles/text";
import "@fontsource/la-belle-aurore";
import { Card, CardBody } from "@chakra-ui/react";
import ic_ai_assistant from "../assets/ic_ai_assistant.png";
import ic_ai_character from "../assets/ic_ai_character.png";
import ic_ai_search from "../assets/ic_ai_search.jpg";
import ic_ai_video from "../assets/ic_ai_video.png";
import ic_ai_voice from "../assets/ic_ai_voice.png";
import ic_beauty_ai from "../assets/ic_beauty_ai.png";
import ic_magic_dance from "../assets/ic_magic_dance.jpg";
import { AppNavigationBar } from "./Navigation";
import { Helmet } from "react-helmet";

const Home = () => {
  const descriptionText = `We are premier app publisher for iOS and Android, we are dedicated to creating high-quality, user-friendly apps that enrich the digital experience of millions of users worldwide. With a diverse portfolio of apps available on both the Apple App Store and Google Play, we cater to various needs and interests, ensuring there is something for everyone.`;

  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Welcome to AppVantage" />
        <meta
          property="og:description"
          content="We are premier app publisher for iOS and Android, we are dedicated to creating high-quality, user-friendly apps."
        />
        <meta property="og:url" content="%PUBLIC_URL%/" />
        <meta property="og:type" content="website" />
        <title>AppVantage | Home</title>
      </Helmet>
      <VStack>
        <AppNavigationBar></AppNavigationBar>
        <Box h={{ base: 10, md: 20 }}></Box>
        <HStack align={"baseline"}>
          <AppText
            fontSize={{ base: 20, md: 40 }}
            fontFamily={`'La Belle Aurore'`}
          >
            Welcome to
          </AppText>
          <Box w={{ base: 0, md: 5 }}></Box>
          <AppText fontSize={{ base: 40, md: 80 }} fontWeight="bold">
            AppVantage
          </AppText>
        </HStack>
        <Box h={{ base: "2px", md: 15 }}></Box>
        <Box w={{ base: "80%", md: "60%" }}>
          <AppText color={"gray"} fontSize={{ base: 13, md: 20 }}>
            {descriptionText}
          </AppText>
        </Box>
        <Box h={{ base: "0px", md: "20px" }}></Box>
        <HStack>
          <Card
            backgroundColor={"#292929"}
            borderRadius={30}
            transform="rotate(-10deg)"
            paddingLeft={{ base: "10px", md: "25px" }}
            paddingRight={{ base: "10px", md: "25px" }}
          >
            <CardBody>
              <VStack>
                <AppText
                  color={"white"}
                  fontSize={{ base: 35, md: 70 }}
                  fontWeight={"bold"}
                  h={{ base: "30px", md: "60px" }}
                >
                  20+
                </AppText>
                <Box h={{ base: "5px", md: "10px" }}></Box>
                <AppText
                  color={"white"}
                  fontSize={{ base: 16, md: 25 }}
                  fontWeight={"bold"}
                >
                  Apps
                </AppText>
              </VStack>
            </CardBody>
          </Card>
          <Box w={{ base: "5px", md: "15px" }}></Box>
          <Box paddingTop={"100px"}>
            <Card
              backgroundColor={"#4F4F4F"}
              borderRadius={30}
              transform="rotate(8deg)"
              paddingLeft={{ base: "25px", md: "50px" }}
              paddingRight={{ base: "25px", md: "50px" }}
            >
              <CardBody>
                <VStack>
                  <AppText
                    color={"white"}
                    fontSize={{ base: 35, md: 70 }}
                    fontWeight={"bold"}
                    h={{ base: "30px", md: "60px" }}
                  >
                    1M+
                  </AppText>
                  <Box h={"10px"}></Box>
                  <AppText
                    color={"white"}
                    fontSize={{ base: 16, md: 25 }}
                    fontWeight={"bold"}
                  >
                    Installers
                  </AppText>
                </VStack>
              </CardBody>
            </Card>
          </Box>
        </HStack>
        <Box h={{ base: "20px", md: "40px" }}></Box>
        <Divider w={{ base: "50%", md: "50%" }}></Divider>
        <Box h={{ base: "20px", md: "40px" }}></Box>
        <AppGallery></AppGallery>
        <Box h={{ base: "100px", md: "200px" }}></Box>
        <AppText color={"gray"}>Ho Chi Minh City, Vietnam</AppText>
      </VStack>
    </div>
  );
};

function AppGallery() {
  return (
    <VStack>
      <HStack>
        <Image
          boxSize={{ base: "50px", md: "90px" }}
          objectFit="cover"
          src={ic_ai_assistant}
          borderRadius={{ base: 10, md: 20 }}
        />
        <Box w={{ base: "0px", md: "15px" }}></Box>
        <Image
          boxSize={{ base: "50px", md: "90px" }}
          objectFit="cover"
          src={ic_ai_character}
          borderRadius={{ base: 10, md: 20 }}
        />
        <Box w={{ base: "0px", md: "15px" }}></Box>
        <Image
          boxSize={{ base: "50px", md: "90px" }}
          objectFit="cover"
          src={ic_ai_search}
          borderRadius={{ base: 10, md: 20 }}
        />
        <Box w={{ base: "0px", md: "15px" }}></Box>
        <Image
          boxSize={{ base: "50px", md: "90px" }}
          objectFit="cover"
          src={ic_ai_video}
          borderRadius={{ base: 10, md: 20 }}
        />
      </HStack>
      <Box h={{ base: "0px", md: "15px" }}></Box>
      <HStack>
        <Image
          boxSize={{ base: "50px", md: "90px" }}
          objectFit="cover"
          src={ic_ai_voice}
          borderRadius={{ base: 10, md: 20 }}
        />
        <Box w={"15px"}></Box>
        <Image
          boxSize={{ base: "50px", md: "90px" }}
          objectFit="cover"
          src={ic_beauty_ai}
          borderRadius={{ base: 10, md: 20 }}
        />
        <Box w={"15px"}></Box>
        <Image
          boxSize={{ base: "50px", md: "90px" }}
          objectFit="cover"
          src={ic_magic_dance}
          borderRadius={{ base: 10, md: 20 }}
        />
      </HStack>
    </VStack>
  );
}

export default Home;
