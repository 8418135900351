import {
  Box,
  VStack,
  CardBody,
  CardHeader,
  Stack,
  Card,
  StackDivider,
} from "@chakra-ui/react";
import React from "react";
import { AppNavigationBar } from "./Navigation";
import { AppText } from "../styles/text";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Contact Us" />
        <meta
          property="og:description"
          content="Find ways to get in touch with our company"
        />
        <meta property="og:url" content="%PUBLIC_URL%/contact" />
        <meta property="og:type" content="website" />
        <title>AppVantage | Contact</title>
      </Helmet>
      <VStack>
        <AppNavigationBar></AppNavigationBar>
        <Box h={"50px"}></Box>
        <Card w={{ base: "80%", md: "30%" }}>
          <CardHeader>
            <AppText fontSize={20} fontWeight="bold">
              How To Contact Us
            </AppText>
          </CardHeader>
          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              <Box>
                <AppText fontSize={15} fontWeight="bold">
                  By Email
                </AppText>
                <Box h={"5px"}></Box>
                <AppText fontSize={15}>info@appvantagelabs.co</AppText>
              </Box>
              <Box>
                <AppText fontSize={15} fontWeight="bold">
                  By Telephone
                </AppText>
                <Box h={"5px"}></Box>
                <AppText fontSize={15}>Coming Soon...</AppText>
              </Box>
            </Stack>
          </CardBody>
        </Card>
      </VStack>
    </div>
  );
};

export default Contact;
