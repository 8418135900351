import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { AppText } from "../styles/text";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import AppTextButton from "../styles/button";

const Support = () => {
  const navigate = useNavigate();

  const goToPolicy = () => {
    navigate("/policy");
  };

  const goToTerm = () => {
    navigate("/term");
  };

  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Support" />
        <meta property="og:description" content="Support" />
        <meta property="og:url" content="%PUBLIC_URL%/support" />
        <meta property="og:type" content="website" />
        <title>Support</title>
      </Helmet>
      <VStack align={"start"} p={10}>
        <AppText fontSize={{ base: 20, md: 40 }} fontWeight="bold">
          Support Information
        </AppText>
        <AppText fontSize={{ base: 12, md: 20 }}>
          For any question or problem that happen during using our apps, please
          refer to below information to contact us:
          <br />
          Email: trucnguyen1203nt@gmail.com
        </AppText>
        <Box height={30}></Box>
        <HStack>
          <AppTextButton
            title="Policy"
            onClicked={goToPolicy}
            backgroundColor={"grey"}
          ></AppTextButton>
          <AppTextButton
            title="Term of Use"
            onClicked={goToTerm}
            backgroundColor={"grey"}
          ></AppTextButton>
        </HStack>
      </VStack>
    </div>
  );
};

export default Support;
