import { extendTheme } from "@chakra-ui/react";
import "@fontsource/roboto";

const AppTheme = extendTheme({
  fonts: {
    heading: `'League Spartan'`,
    body: `'League Spartan'`,
  },
  colors: {
    background: {
      500: "#f1f1f1",
    },
    card: {
      500: "#F6FEFF",
    },
  },
});

export default AppTheme;
