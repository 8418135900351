import "@fontsource/league-spartan";
import React from "react";
import { Text } from "@chakra-ui/react";

const defaultFontFamily = "League Spartan";

export function AppText({
  children,
  fontSize,
  color,
  fontWeight,
  fontFamily,
  h,
}) {
  return (
    <Text
      fontFamily={fontFamily ?? defaultFontFamily}
      fontSize={fontSize ?? "14"}
      color={color ?? "black"}
      fontWeight={fontWeight}
      h={h}
    >
      {children}
    </Text>
  );
}
