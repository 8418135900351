import { Box } from "@chakra-ui/react";

function AppScaffold({ children, padding }) {
  return (
    <Box color="background" padding={padding ?? 5}>
      {children}
    </Box>
  );
}

export default AppScaffold;
