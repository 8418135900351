import React from "react";
import { HStack, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ic_app_black from "../assets/ic_app_black.png";
import AppTextButton from "../styles/button";

export function AppNavigationBar() {
  const navigate = useNavigate();

  const goToContact = () => {
    navigate("/contact");
  };

  const goToHome = () => {
    navigate("/");
  };

  return (
    <HStack spacing={{ base: "15%", md: "20%" }} justifyContent="center">
      <AppTextButton title="Home" onClicked={goToHome}></AppTextButton>
      <Image
        boxSize={{ base: "50px", lg: "60px" }}
        objectFit="cover"
        src={ic_app_black}
      />
      <AppTextButton title="Contact" onClicked={goToContact}></AppTextButton>
    </HStack>
  );
}
